import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'hash',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			name: 'Home',
			component: () => import('../views/Home'),
			props: true,
			meta: { requiresAuth: true },
		},
		{
			path: '/visit',
			name: 'Visit',
			component: () => import('../views/Visit'),
			props: true,
			meta: { requiresAuth: true },
		},
		{
			path: '/quick',
			name: 'Quick',
			component: () => import('../views/Quick'),
			props: true,
			meta: { requiresAuth: true },
		},
		{
			path: '/interests',
			name: 'Interests',
			component: () => import('../views/Interests'),
			props: true,
			meta: { requiresAuth: true },
		},
		{
			path: '/leads',
			name: 'Leads',
			component: () => import('../views/Leads'),
			props: true,
			meta: { requiresAuth: true },
		},

		/* --- MISC --- */
		{
			path: '/login/:token?',
			name: 'Login',
			component: () => import('../views/Login'),
			props: true,
			meta: { requiresAuth: false },
			beforeEnter(routeTo, routeFrom, next) {
				if (routeTo.params.token) {
					store.dispatch('getUser', routeTo.params.token).then((response) => {
						if (response === true) {
							next({ name: 'Home' })
						} else {
							next({
								name: 'LoginError',
								params: { reason: response },
							})
						}
					})
				} else {
					next()
				}
			},
		},
		{
			path: '/login-failed/:reason?',
			name: 'LoginError',
			component: () => import('../views/LoginError'),
			props: true,
			meta: { requiresAuth: false },
			beforeEnter(routeTo, routeFrom, next) {
				next()
			},
		},
		{
			path: '/404',
			name: '404',
			component: () => import('../views/Error404.vue'),
			props: true,
			meta: { requiresAuth: false },
		},
		{
			path: '*',
			redirect: { name: '404', params: { resource: 'page' } },
			meta: { requiresAuth: false },
		},
	],
})

router.beforeEach((routeTo, routeFrom, next) => {
	// get user from localStorage
	if (!store.state.user.ID) {
		store.getters.getUser
	}

	// check authentication requirements of the route
	if (routeTo.matched.some((record) => record.meta.requiresAuth) && !store.state.user.ID) {
		next({ name: 'Login' })
	} else {
		next()
	}
})

export default router
