import Vue from 'vue'
import Vuex from 'vuex'
import RestService from '../services/RestService'
import moment from 'moment'
import _ from 'lodash'
import { guid } from '@/helpers'

Vue.use(Vuex)

Array.prototype.move = function (from, to) {
	this.splice(to, 0, this.splice(from, 1)[0])
}

export default new Vuex.Store({
	modules: {},
	state: {
		version: '2.0.2',
		user: { ID: 0 },
		token: '',
		nonce: '',
		sfMode: false,
		storage: {
			user: 'tsa_user',
			token: 'tsa_token',
			events: 'tsa_events',
			lead: 'tea_lead_',
			counter: 'tsa_lead_counter',
		},
		dialogs: {
			scanner: { active: false, ID: 0 },
			codeInput: { active: false, ID: 0 },
			newVersion: { active: false, ID: 0 },
		},
		feedback: {
			text: '',
			show: '',
		},
		// Setup Data
		events: [],
		// Leads
		formData: {},
		editMode: false,
		leads: {},
		// Form Data
		default: {
			event: '',
			other: '',
			date: '',
		},
		lastError: '',
	},
	actions: {
		getUser({ state }, token) {
			state.token = token
			return RestService.get('/sso/login-status').then((response) => {
				if (response.data.user) {
					localStorage.setItem(state.storage.user, JSON.stringify(response.data.user))
					localStorage.setItem(state.storage.token, token)
					state.user = response.data.user
					return true
				} else {
					state.token = ''
					return 'unknown-token'
				}
			})
		},
		logout({ state }) {
			return new Promise((resolve) => {
				state.user = { ID: 0 }
				state.token = ''
				localStorage.removeItem(state.storage.user)
				localStorage.removeItem(state.storage.token)
				resolve()
			})
		},
		loadCache({ state }) {
			const events = localStorage.getItem(state.storage.events)
			if (events) state.events = JSON.parse(events)
			for (let key in localStorage) {
				if (key.indexOf(state.storage.lead) === 0) {
					let lead = localStorage.getItem(key)
					state.leads[key.replace(state.storage.lead, '')] = JSON.parse(lead)
				}
			}
		},
		loadSetup({ state }) {
			RestService.get('/web-app/events').then((response) => {
				if (response.data.success) {
					localStorage.setItem(state.storage.events, JSON.stringify(response.data.events))
					return true
				}
			})
		},
		toggleDialog({ state }, data) {
			state.dialogs[data.dialog].active = !state.dialogs[data.dialog].active
			state.dialogs[data.dialog].ID = data.ID || 0
		},
		showFeedback({ state }, text) {
			state.feedback.text = text
			state.feedback.show = true
		},
		getBadgeData({ state, dispatch, commit }, badge) {
			const data = {
				badge: badge,
				event: state.formData.values.congressname,
			}
			state.lastError = ''
			return RestService.post('/web-app/badge', data).then((response) => {
				if (response.data.success) {
					const keys = ['email', 'name', 'lastName', 'country', 'facility', 'department']

					state.formData.values.badge = response.data.badge
					_.forEach(keys, (key) => {
						if (response.data.user[key] !== undefined) {
							state.formData.values[key] = response.data.user[key]
						}
					})

					if (response.data.lead_id !== undefined) {
						state.formData.lead_id = response.data.lead_id
						dispatch('storeData', false)
					}
					commit('TRACE')
					return true
				} else {
					state.lastError = 'unknown error'
					if (response.data && response.data.badge) {
						state.lastError = response.data.badge.error
					} else if (response.data && response.data.error_message) {
						state.lastError = response.data.error_message
					}
					return false
				}
			})
		},
		checkVersion({ state, dispatch }) {
			return RestService.get('/web-app/version').then((response) => {
				if (response.data.version !== state.version) {
					dispatch('toggleDialog', { dialog: 'newVersion' })
				}
			})
		},
		createVisit({ state, commit }, isQuickLead) {
			return new Promise((resolve) => {
				state.formData = {
					lead_id: 0,
					uid: guid(),
					dateTime: moment().format('YYYY-MM-DD HH:mm'),
					values: {
						quickLead: isQuickLead,
						congressdate: state.default.date,
						congressname: state.default.event,
						congressname2: state.default.other,
						completedby: state.user.first_name + ' ' + state.user.last_name,
					},
				}
				state.editMode = false
				commit('TRACE')
				resolve()
			})
		},
		editLead({ state, commit }, uid) {
			return new Promise((resolve) => {
				state.formData = _.cloneDeep(state.leads[uid])
				state.editMode = true
				commit('TRACE')
				resolve()
			})
		},
		uploadLead({ state, dispatch }, uid) {
			return RestService.post('/web-app/data', state.leads[uid]).then((response) => {
				if (response.data.success) {
					dispatch('deleteLead', uid).then(() => {
						return true
					})
				} else {
					return false
				}
			})
		},
		saveForm({ state, commit }, data) {
			return new Promise((resolve) => {
				const plainData = _.cloneDeep(data)
				_.forEach(plainData, (val, key) => {
					state.formData.values[key] = val
				})
				commit('TRACE')
				resolve()
			})
		},
		abortForm({ state, commit }) {
			return new Promise((resolve) => {
				state.formData = {}
				state.editMode = false
				commit('TRACE')
				resolve()
			})
		},
		storeData({ state, commit }, isOnline) {
			return new Promise((resolve) => {
				const plainData = _.cloneDeep(state.formData)
				state.leads[plainData.uid] = plainData
				state.editMode = false
				commit('TRACE')

				localStorage.setItem(state.storage.lead + plainData.uid, JSON.stringify(plainData))
				if (isOnline) {
					const data = {
						batch_data: [plainData],
					}
					return RestService.post('/web-app/autosave', data).then((response) => {
						if (response.data.success) {
							const uid = response.data.batch[0].uid
							state.leads[uid].lead_id = response.data.batch[0].lead_id
							const plainData = _.cloneDeep(state.leads[uid])
							localStorage.setItem(state.storage.lead + uid, JSON.stringify(plainData))
						}
						resolve()
					})
				} else {
					resolve()
				}
			})
		},
		deleteLead({ state, commit }, uid) {
			return new Promise((resolve) => {
				state.leads = _.omit(state.leads, uid)
				localStorage.removeItem(state.storage.lead + uid)
				commit('TRACE')
				resolve()
			})
		},
	},
	getters: {
		getUser: (state) => {
			const user = localStorage.getItem(state.storage.user)
			const token = localStorage.getItem(state.storage.token)
			if (user) {
				state.user = JSON.parse(user)
				state.token = token
				return true
			} else {
				return false
			}
		},
	},
	mutations: {
		TRACE() {},
		toggleMode(state) {
			state.sfMode = !state.sfMode
		},
	},
})
