import axios from 'axios'
import store from '../store'
import router from '../router'
import _ from 'lodash'

const colors = {
	info: 'silver',
	notice: 'cyan',
	success: 'green',
	warning: 'orange',
	error: 'red',
	debug: 'gold',
}

const apiClient = axios.create({
	baseURL: '/wp-json/',
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	timeout: 10000,
})

apiClient.interceptors.response.use(
	(response) => {
		if (typeof response.headers['app-version'] !== 'undefined') {
			store.state.versionCheck = response.headers['app-version']
		}
		if (typeof response.data.logfile !== 'undefined') {
			console.group('API LOG')
			_.forEach(response.data.logfile, (item) => {
				let msg = `%c${item.level}: %c`
				if (item.area) msg += `[${item.area}] `
				msg += item.message
				console.log(msg, 'font-weight:bold;text-transform:uppercase;color:' + colors[item.level] || 'white', 'white')
			})
			console.groupEnd()
		}
		return response
	},
	(error) => {
		if (typeof error.response.data.code !== 'undefined') {
			if (error.response.data.code.indexOf('jwt_') === 0) {
				store.commit('killSession')
				router.push('/login')
				return false
			}
		}
		throw error
	}
)

function auth_headers(customConfig) {
	let config = {
		headers: { 'api-authorization': store.state.token },
	}

	switch (window.location.hostname) {
		case 'tradeshow-brainlab.schalk-development.de':
			config.headers.Authorization = 'Basic c2NoYWxrOndwU2VjMjAxNiFUYQ=='
			break
	}

	if (typeof customConfig === 'object') {
		config = Object.assign(config, customConfig)
	}

	return config
}

export default {
	get(route) {
		return apiClient.get(route, auth_headers())
	},
	post(route, data) {
		return apiClient.post(route, data, auth_headers())
	},
	put(route, data) {
		return apiClient.put(route, data, auth_headers())
	},
	delete(route) {
		return apiClient.delete(route, auth_headers())
	},
	file(route, data) {
		return apiClient.post(route, data, auth_headers({ 'Content-Type': 'multipart/form-data' }))
	},
	download(route, data) {
		return apiClient.post(route, data, auth_headers({ responseType: 'blob' }))
	},
}
