<template>
	<v-app :class="{ 'background-image--app': !user.ID }">
		<NavbarComponent></NavbarComponent>

		<v-main app :class="[{ login: !user.ID }, 'pb-12 pb-sm-0']">
			<router-view />
		</v-main>

		<FooterComponent />

		<ScannerDialog />
		<CodeInputDialog />
		<NewVersionDialog />
		<FeedbackComponent />
	</v-app>
</template>

<script>
import NavbarComponent from '@/components/Navbar'
import FooterComponent from '@/components/Footer'
import ScannerDialog from '@/components/dialogs/Scanner'
import NewVersionDialog from '@/components/dialogs/NewVersion'

import { mapState } from 'vuex'
import FeedbackComponent from '@/components/Feedback'
import CodeInputDialog from '@/components/dialogs/CodeInput.vue'

export default {
	name: 'App',
	components: { CodeInputDialog, NavbarComponent, FooterComponent, FeedbackComponent, NewVersionDialog, ScannerDialog },
	data: () => ({
		checkInterval: 30000,
	}),
	computed: {
		...mapState(['user']),
	},
	created() {
		if (window.location.hash !== '#/' && window.location.hash !== '#/login') {
			this.$router.push('/')
		}
		window.setInterval(this.checkScript, this.checkInterval)
		window.addEventListener('focus', () => {
			this.checkScript()
		})
	},
	mounted() {
		this.$store.dispatch('loadCache', this.isOnline)
		if (this.isOnline) {
			this.$store.dispatch('loadSetup', this.isOnline)
		}
	},
	methods: {
		checkScript() {
			if (this.isOnline) {
				this.$store.dispatch('checkVersion')
			}
		},
	},
}
</script>
<style>
:root {
	--color-primary: #ee2375;
	--header-height-login: 650px;
	--header-height-logged: 250px;
	--input-height: 54px;
	--button-height: 42px;
	--button-primary-hover: #c10e56;
}

.v-input input,
.v-text-field__slot {
	max-height: var(--input-height);
	height: var(--input-height);
}

.v-text-field input:not([role='button']) {
	padding: 10px 0 0 !important;
}

input[role='button'] {
	padding: 16px 0 10px !important;
}

.v-file-input__text,
textarea {
	padding-top: 10px !important;
}

.v-input__append-inner {
	margin-top: 16px !important;
}

.v-input__prepend-inner {
	margin-top: 18px !important;
}

.v-text-field--outlined.v-input--dense .v-label {
	top: 16px !important;
}

.v-text-field--outlined.v-input--dense .v-label--active {
	transform: translateY(-12px) scale(0.75) !important;
}

fieldset legend {
	width: 0 !important;
}

.v-btn {
	letter-spacing: 0;
	transition: background-color 0.3s ease-out;
}

.v-btn:hover .v-btn__content span {
	text-decoration: underline;
}

.v-btn.primary:hover {
	background-color: var(--button-primary-hover) !important;
	border-color: var(--button-primary-hover) !important;
}

.v-btn.v-size--default {
	height: var(--button-height) !important;
}

.v-btn.v-btn--is-elevated {
	box-shadow: none;
}

.v-textarea .v-text-field__slot {
	height: auto;
	max-height: 100%;
}

.background-image--app,
.login {
	background-image: url('@/assets/images/MicrosoftTeams-image.png');
	background-size: cover;
	background-position: center;
}

.background-image--app {
	height: var(--header-height-logged);
}

.login {
	height: fit-content;
	min-height: var(--header-height-login);
}

.background-color--grey {
	background-color: #dfdedf;
}
.background-color--white {
	background-color: #ffffff;
}
.background-color--primary {
	background-color: var(--color-primary);
}

.v-input--switch__thumb,
.theme--light.v-input--switch .v-input--switch__thumb,
.v-input--selection-controls__thumb {
	color: #fff !important;
}

.v-input--switch__track {
	color: #dfdedf !important;
	opacity: 1 !important;
}

.v-input--switch__track.primary--text {
	color: var(--color-primary) !important;
}

.v-input--selection-controls__ripple {
	opacity: 0.25 !important;
}

.v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
	content: none;
}

.v-input__slot fieldset {
	color: #dfdedf;
}

.v-select__selections {
	color: #8f8c8f !important;
}

.v-select__selections,
.v-input input,
.v-input textarea {
	color: #5e5d61 !important;
}
.font-weight-medium {
	font-family: 'BrainlabNow-Medium', sans-serif;
}
</style>
