<template>
	<v-dialog v-model="viewDialog" width="400" persistent>
		<v-card>
			<v-app-bar color="primary" dark dense>
				<v-app-bar-title>Please Note</v-app-bar-title>
			</v-app-bar>
			<v-card-text class="pt-4">
				<p><v-icon color="warning" left>mdi-alert-outline</v-icon> There is a new <strong>app version</strong> available.</p>
				<p>
					You can reload the app by using the browser's reload button twice in a row.<br />
					Usually this will skip the page cache.
				</p>
				<p>
					If this doesn't work, <strong>upload your leads</strong> and <strong>clear your browser's cache</strong> to get the
					latest version.
				</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="primary" @click="close">Ok</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'NewVersionDialog',
	computed: {
		...mapState(['dialogs']),
		viewDialog: {
			get() {
				return this.dialogs.newVersion.active
			},
			set() {
				this.$store.dispatch('toggleDialog', { dialog: 'newVersion' })
			},
		},
	},
	methods: {
		close() {
			this.$store.dispatch('toggleDialog', { dialog: 'newVersion' })
		},
	},
}
</script>

<style scoped></style>
