<template>
	<v-dialog v-model="viewDialog" width="580" persistent>
		<v-card>
			<v-card-text class="pt-4 scanner-container">
				<v-row dense>
					<v-col cols="8" sm="9">
						<v-text-field
							v-model="badgeNumber"
							type="number"
							class="number-input"
							outlined
							dense
							single-line
							autofocus
							hide-spin-buttons
							hide-details />
					</v-col>
					<v-col cols="4" sm="3">
						<v-btn color="primary" class="mt-2 text-none" @click="getData" :loading="processing" :disabled="done">
							Get Data
						</v-btn>
					</v-col>
				</v-row>
				<v-alert v-if="lastError" outlined type="error" class="text-center mt-3" dense>
					{{ lastError }}
				</v-alert>
			</v-card-text>
			<v-card-actions class="pb-4">
				<v-btn color="primary" class="text-none" text v-show="!done" @click="close">
					<v-icon left>mdi-chevron-left</v-icon>
					Go back
				</v-btn>
				<v-spacer />
				<v-btn color="primary" class="text-none" :disabled="!done" @click="close">
					Continue to form
					<v-icon right>mdi-arrow-right-thin</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'CodeInputDialog',
	data: () => ({
		badgeNumber: '',
		processing: false,
		isValid: false,
		done: false,
	}),
	computed: {
		...mapState(['dialogs', 'lastError']),
		viewDialog: {
			get() {
				return this.dialogs.codeInput.active
			},
			set() {
				this.$store.dispatch('toggleDialog', { dialog: 'codeInput' })
			},
		},
	},
	methods: {
		getData() {
			if (this.badgeNumber) {
				this.processing = true
				this.$store.dispatch('getBadgeData', this.badgeNumber).then((valid) => {
					this.processing = false
					this.isValid = valid
					if (valid) {
						this.done = true
						this.close()
					}
				})
			} else {
				this.$store.state.lastError = 'Please enter the Badge ID'
			}
		},
		close() {
			this.$store.dispatch('toggleDialog', { dialog: 'codeInput' })
		},
		reset() {
			this.badgeNumber = ''
			this.processing = false
			this.isValid = false
			this.done = false
			this.$store.state.lastError = ''
		},
	},
	watch: {
		viewDialog: 'reset',
	},
}
</script>
<style>
.v-text-field.number-input .v-text-field__slot input {
	padding-top: 0 !important;
}
</style>
