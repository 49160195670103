<template>
<div>
	<v-expand-x-transition appear>
		<v-navigation-drawer 
			v-if="user.ID && drawer" 
			v-model="drawer"
			app color="primary" 
			clipped absolute right			
			disable-resize-watcher
			v-bind:width="365"
			style="position:fixed;"
		>
			<div class="brand px-4 py-3 mt-16">
				<router-link to="/" class="mobile-link">LeadGen</router-link>
			</div>
			<v-divider></v-divider>
			<router-link to="/leads" class="mobile-link px-4 py-3">
				Registered Leads
			</router-link>
			<v-divider></v-divider>
			<div icon class="mobile-link px-4 py-3" @click="logout">
				Logout
			</div>
			<v-divider></v-divider>
		</v-navigation-drawer>
	</v-expand-x-transition>

		<v-app-bar color="primary" class="flex-grow-0" app dark short clipped-right>
		<router-link to="/" class="d-inline-block">
			<img src="/assets/images/logo-brainlab-white.svg" alt="Brainlab" height="20" class="mt-1" />
		</router-link>
		<div class="brand pl-4 ml-4 mr-2 mr-sm-4" v-if="(user.ID && $vuetify.breakpoint.mdAndUp) || !user.ID">
			<router-link to="/" class="d-inline-block white--text text-decoration-none">LeadGen</router-link>
		</div>
		<router-link v-if="user.ID" to="/leads" class="white--text text-decoration-none mx-4 hidden-sm-and-down">
			Registered Leads
		</router-link>

		<v-app-bar-nav-icon v-if="user.ID" @click.stop="drawer = !drawer" class="ml-auto hidden-md-and-up">
			<v-icon>mdi-menu</v-icon>
		</v-app-bar-nav-icon>

		<v-btn icon class="ml-auto text-decoration-none hidden-sm-and-down" v-if="user.ID" @click="logout">
			<v-icon>$logout</v-icon>
		</v-btn>
	</v-app-bar>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'NavbarComponent',

	data() {
		return {
			drawer: false,
		}
	},

	computed: {
		...mapState(['user']),
	},

	methods: {
		logout() {
			this.$store.dispatch('logout').then(() => {
				this.$router.push('/login')
			})
		},
	},
}
</script>

<style lang="postcss" scoped>
@media screen and (min-width: 960px) {
	.brand {
		border-left: 1px solid #fff;
	}
}

.menuable__content__active {
	background-color: #fff;
	display: flex;
	flex-direction: column;
  padding: 10px;
}

.mobile-link {
	color: #fff;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	width: 100%;
}

.mobile-link:hover {
	color: rgba(255,255,255,.75);
	text-decoration: underline;
}

.v-divider {
	border-color: #fff !important;
}
</style>
