<template>
	<v-snackbar v-model="viewDialog" :timeout="timeout" content-class="text-center" min-width="100">
		{{ feedback.text }}
	</v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'FeedbackComponent',
	data: () => ({
		timeout: 4000,
	}),
	computed: {
		...mapState(['feedback']),
		viewDialog: {
			get() {
				return this.feedback.show
			},
			set(val) {
				this.$store.state.feedback.show = val
			},
		},
	},
}
</script>
