import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import LogoutIcon from '@/components/icons/LogoutIcon'
import ArrowRightLong from '@/components/icons/arrow-right-long'
import LoginAccount from '@/components/icons/login-account'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#EE2375',
				secondary: '#0083BF',
				error: 'ee2375',
				greyOne: '#434549',
				greyTwo: '#5e5d61',
				greyTree: '#8f8c8f',
				greyFour: '#b4b1b3',
				greyFive: '#dfdedf',
				greySix: '#f5f5f5',
				brainlabPurple: '#7F3F98',
				brainlabGreen: '#00A886',
				brainlabOrange: '#FAA21B',
			},
		},
	},
	icons: {
		values: {
			logout: {
				component: LogoutIcon,
			},
			ArrowRightLong: {
				component: ArrowRightLong,
			},
			LoginAccount: {
				component: LoginAccount,
			},
		},
	},
})
