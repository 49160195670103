<template>
	<v-footer color="background-color--grey greyFive">
		<v-row class="mt-10">
			<v-col cols="12" sm="6" class="text-center text-sm-left">
				<a
					href="https://www.brainlab.com/privacy-policy/"
					target="_blank"
					class="d-block text--darken-grey greyOne--text text-decoration-none mb-4">
					Privacy Policy
				</a>
				<a
					href="https://www.brainlab.com/compliance/cookie-declaration/"
					target="_blank"
					class="d-block text--darken-grey greyOne--text text-decoration-none">
					Cookie Declaration
				</a>
				<a href="https://www.brainlab.com" target="_blank">
					<img src="/assets/images/logo-brainlab-grey.svg" alt="Brainlab" height="16" class="mt-10" />
				</a>
			</v-col>
			<v-col cols="12" sm="6" class="d-flex align-end justify-center justify-sm-end">
				<div class="text--darken-grey greyOne--text no-select" @dblclick.stop="toggleMode">&copy; 2024 Brainlab AG</div>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	name: 'FooterComponent',
	methods: {
		toggleMode() {
			this.$store.commit('toggleMode')
		},
	},
}
</script>
<style>
.no-select {
	user-select: none;
}
</style>
