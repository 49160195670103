/**
 * New Version 9.2.13
 * @see https://www.dynamsoft.com/barcode-reader/docs/web/programming/javascript/samples-demos/helloworld-vuejs.html?ver=latest
 */
import { BarcodeReader } from 'dynamsoft-javascript-barcode'

/**
 * To use the library, you need to first specify a license key using the API "license" as shown below.
 */
BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMzYzMzU1LTZhMDY0ZDZlIiwib3JnYW5pemF0aW9uSUQiOiIzNjMzNTUifQ=='

/**
 * You can visit https://www.dynamsoft.com/customer/license/trialLicense?utm_source=github&product=dbr&package=js to get your own trial license good for 30 days.
 * Note that if you downloaded this sample from Dynamsoft while logged in, the above license key may already be your own 30-day trial license.
 * For more information, see https://www.dynamsoft.com/barcode-reader/programming/javascript/user-guide/?ver=9.2.13&utm_source=github#specify-the-license or contact support@dynamsoft.com.
 */

BarcodeReader.engineResourcePath = 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/'
BarcodeReader._bUseFullFeature = true
