<template>
	<svg xmlns="http://www.w3.org/2000/svg" class="a-icon a-icon--arrow-right-long" viewBox="0 0 20.599 20.599">
		<circle cx="4.522" cy="4.522" r="4.522" transform="translate(6.028)" fill="currentColor"></circle>
		<path
			d="M4.522,2.01A2.512,2.512,0,1,0,7.034,4.522,2.515,2.515,0,0,0,4.522,2.01m0-2.01A4.522,4.522,0,1,1,0,4.522,4.522,4.522,0,0,1,4.522,0Z"
			transform="translate(6.028)"
			fill="currentColor"></path>
		<path
			d="M20.6,10.55H0A10.71,10.71,0,0,1,.809,6.442,10.546,10.546,0,0,1,3.016,3.089,10.254,10.254,0,0,1,6.29.829a10.111,10.111,0,0,1,8.018,0,10.254,10.254,0,0,1,3.274,2.26,10.532,10.532,0,0,1,2.208,3.354,10.719,10.719,0,0,1,.809,4.107Z"
			transform="translate(0 10.048)"
			fill="currentColor"></path>
		<path
			d="M-8264.64,7298.54a8.727,8.727,0,0,0-.428-1.331,8.539,8.539,0,0,0-1.786-2.716,8.264,8.264,0,0,0-2.636-1.821,8.027,8.027,0,0,0-3.211-.662,8.015,8.015,0,0,0-3.209.662,8.246,8.246,0,0,0-2.635,1.82,8.532,8.532,0,0,0-1.788,2.717,8.587,8.587,0,0,0-.428,1.331h16.12m2.239,2.01H-8283a10.71,10.71,0,0,1,.809-4.107,10.55,10.55,0,0,1,2.208-3.354,10.228,10.228,0,0,1,3.273-2.26,10.016,10.016,0,0,1,4.009-.83,10.009,10.009,0,0,1,4.009.83,10.221,10.221,0,0,1,3.274,2.26,10.534,10.534,0,0,1,2.208,3.354,10.727,10.727,0,0,1,.809,4.106Z"
			transform="translate(8282.999 -7279.951)"
			fill="currentColor"></path>
	</svg>
</template>
<script>
export default {
	name: 'LoginAccount',
}
</script>
<style>
.a-icon {
	fill: currentColor;
}
</style>
