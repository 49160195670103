<template>
	<svg xmlns="http://www.w3.org/2000/svg" class="a-icon a-icon--arrow-right-long" viewBox="0 0 19 9.61">
		<path d="M14.03,0l-1.29,1.24l2.78,2.68H0v1.76h15.52l-2.78,2.68l1.29,1.24L19,4.81L14.03,0z" />
	</svg>
</template>

<script>
export default {
	name: 'ArrowRightLong',
}
</script>
<style>
.a-icon {
	fill: currentColor;
}
</style>
