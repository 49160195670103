<template>
	<v-dialog v-model="viewDialog" width="580" persistent>
		<v-card>
			<v-card-text class="pt-4 scanner-container">
				<div ref="scanner" class="barcode-scanner dce-video-container"></div>
				<div v-show="!isScanning" class="validation-layer justify-center align-center">
					<div v-show="!isReady" class="text-center">
						<v-progress-circular indeterminate color="primary"></v-progress-circular>
						<div>Scanner loading ...</div>
					</div>
					<div v-show="processing" class="text-center">
						<v-progress-circular indeterminate color="primary"></v-progress-circular>
						<div>Processing</div>
					</div>
					<div v-if="done && isValid" class="text-center">
						<strong>Scan successful!</strong><br />
						Badge data received.
					</div>
					<div v-if="done && !isValid" class="text-center">
						This is not a valid badge!<br />
						<v-btn color="primary" small @click="unpauseScanning"> Try again </v-btn>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="pb-4">
				<v-btn color="primary" class="text-none" text v-show="!done" @click="close">
					<v-icon left>mdi-chevron-left</v-icon>
					Go back
				</v-btn>
				<v-spacer />
				<v-btn color="primary" class="text-none" :disabled="!done" @click="close">
					Continue to form
					<v-icon right>mdi-arrow-right-thin</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import '@/Dynamsoft'
import { BarcodeScanner } from 'dynamsoft-javascript-barcode'
BarcodeScanner._bUseFullFeature = true

export default {
	name: 'ScannerDialog',
	data: () => ({
		scanner: null,
		isReady: false,
		isScanning: false,
		processing: false,
		isValid: false,
		done: false,
	}),
	computed: {
		...mapState(['dialogs']),
		viewDialog: {
			get() {
				return this.dialogs.scanner.active
			},
			set() {
				this.$store.dispatch('toggleDialog', { dialog: 'scanner' })
			},
		},
	},
	methods: {
		setupCamera(active) {
			// open dialog
			if (active) {
				this.resetValues()

				BarcodeScanner.createInstance()
					.then((s) => {
						this.scanner = s
						this.scanner.updateRuntimeSettings('coverage').then(() => {
							this.scanner.getRuntimeSettings().then((settings) => {
								settings.deblurLevel = 9
								this.scanner.updateRuntimeSettings(settings)
							})
						})
						this.scanner.onUniqueRead = (txt, result) => {
							this.pauseScanning()
							this.processScan(result, txt)
						}
						this.scanner
							.setUIElement(this.$refs.scanner)
							.then(() => {
								this.scanner
									.show()
									.then(() => {
										this.isScanning = true
										this.isReady = true
									})
									.catch((ex) => {
										console.error(ex.message || ex)
										this.scanner.hide()
									})
							})
							.catch((ex) => {
								console.error(ex.message || ex)
								this.scanner.hide()
							})
					})
					.catch((ex) => {
						console.error(ex.message || ex)
					})
			}
			// close dialog
			else {
				this.pauseScanning()
				this.scanner.hide()
			}
		},
		resetValues() {
			this.isReady = false
			this.isScanning = false
			this.processing = false
			this.isValid = false
			this.done = false
		},
		pauseScanning() {
			this.isScanning = false
			this.scanner.pause()
		},
		unpauseScanning() {
			this.scanner.play()
			this.isScanning = true
		},
		processScan(result, txt) {
			this.processing = true
			this.$store.dispatch('getBadgeData', txt).then((valid) => {
				this.processing = false
				this.isValid = valid
				this.done = true
			})
		},
		close() {
			this.$store.dispatch('toggleDialog', { dialog: 'scanner' })
		},
	},
	async mounted() {
		try {
			//Load the library on page load to speed things up.
			await BarcodeScanner.loadWasm()
		} catch (ex) {
			let errMsg
			if (ex.message.includes('network connection error')) {
				errMsg =
					'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.'
			} else {
				errMsg = ex.message || ex
			}
			console.error(errMsg)
		}
	},
	watch: {
		viewDialog: 'setupCamera',
	},
}
</script>

<style>
.scanner-container {
	position: relative;
}
.barcode-scanner {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 300px;
}
.validation-layer {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: hsla(0, 0%, 100%, 0.8);
}
</style>
